<template lang="pug">
div
  .container-fluid
    .row
      .col
        h2.text-center {{plateName}}  
    .row
      .col-12.col-sm-6
        .form-group
          label(for="house") Назва вулиці
          StreetName(
            ref="search"
            )
      .col-6.col-sm-3
        .form-group
          label(for="house") Номер будинку
          input#house.form-control(type="text", maxlength="8", v-model='store.inputNumber')
      .col-6.col-sm-3
         .form-group
          label Напрямки

          .btn-group.directions(role="group")
            button.btn.btn-primary(type="button", :class="{ active: store.dirLeft }", @click="directions(), store.dirLeft = true")
              svg(width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg")
                path(d="M11 9.5H14.2C15.8802 9.5 16.7202 9.5 17.362 9.827C17.9265 10.1146 18.3854 10.5735 18.673 11.138C19 11.7798 19 12.6198 19 14.3V25.5" stroke="currentColor" stroke-width="2")
                path(d="M13.5352 6L9.99963 9.5355L13.5352 13.0711" stroke="currentColor" stroke-width="2")

            button.btn.btn-primary(type="button", :class="{ active: store.dirForward }", @click="directions(), store.dirForward = true")
              svg(width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg")
                path(d="M15.75 6V26" stroke="currentColor" stroke-width="2")
                path(d="M19.7324 9.2678L15.7575 5.29288L11.7827 9.2678" stroke="currentColor" stroke-width="2")

            button.btn.btn-primary(type="button", :class="{ active: store.dirRight }", @click="directions(), store.dirRight = true")
              svg(width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg")
                path(d="M21 9.5H17.8C16.1198 9.5 15.2798 9.5 14.638 9.827C14.0735 10.1146 13.6146 10.5735 13.327 11.138C13 11.7798 13 12.6198 13 14.3V25.5" stroke="currentColor" stroke-width="2")
                path(d="M18.4648 6L22.0004 9.5355L18.4648 13.0711" stroke="currentColor" stroke-width="2")
         

  BulkDownloadList()

  .container-fluid
    .row
      .col.preview
        // SVG defs
        svg(
          width="0",
          height="0",
          viewBox="0,0,0,0",
          viewport xmlns="http://www.w3.org/2000/svg"
        )
          SvgDefs(            
              :plateWidth = "this.plateWidth"            
              :clipWidth = '1'
              )

        // SVG Placeholder
        #containerPlaceholder(
          v-if="isPlaceholder"
          )
          svg#svgPlaceholder(:width="sizeM(480)", :height="sizeM(280)",:viewBox="sizeM(-20) +' '+ sizeM(-20) +' '+ sizeM(520) +' '+ sizeM(320)", :class="{ material: isMaterial}", @click="isMaterial = !isMaterial", viewport xmlns="http://www.w3.org/2000/svg")
                        
            rect.plate(:width="sizeM(480)",:height="sizeM(280)" rx="16" ry="16" fill="#0075c9" stroke="#000" stroke-width=".25")
            g#refElements(style="display: none;")
              rect(ref="refLimit",:width="sizeM(sizeXR(0)-this.plateLimit)",:height="sizeM(280)",:x="sizeM(50)" y="0" fill="none" stroke="none")            
            
            text.name(:x="sizeM(54)",:y="sizeM(54)",:font-size="sizeM(25)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-SemiBold; letter-spacing: .05em; fill: #fff;") {{mainFamilyPlace}}

            text.num(:x="sizeM(54)",:y="sizeM(245)",:font-size="sizeM(148)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .018em; fill: #fff;")
            
            g(:transform="'translate('+ sizeM(48) +','+ sizeM(245) +')'")
              text#refDigit(:x="0",y="0", :font-size="sizeM(this.fontNumSize)" letter-spacing="0.01em" dominant-baseline="text-top", text-anchor="start", fill="#fff" style="font-weight: 400; font-family: DniproCity-Medium;") {{inputNumberPlace}}
            
            text.translit(:x="sizeM(54)",:y="sizeM(89)",:font-size="sizeM(25)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .035em; fill: #fff;") {{mainTranslitPlace}}
            
            g#direction(:transform="'translate('+ sizeM(sizeXR(40)) +',' + sizeM(125) +'), scale(' + sizeM(-1) +','+ sizeM(1) +')'")
              path#forward(d="M50.9 21.4 29.5 0 8.1 21.4l3 3L27.2 8.2v111.5h4.2V7.9l16.5 16.5z" fill="#fff" v-if ="store.dirForward")
              path#right(d="M39.2 18.2H8.1L23.3 3l-3-3L0 20.3l20.3 20.3 3-3L8.1 22.4h31.1c8.6 0 15.6 7 15.6 15.6v82H59V38c0-10.9-8.9-19.8-19.8-19.8z" fill="#fff" v-if ="store.dirRight")
              path#left(path d="M19.9 18.2H51L35.8 3l3-3L59 20.3 38.8 40.6l-3-3L51 22.4H19.9c-8.6 0-15.6 7-15.6 15.6v82H0V38c0-10.9 8.9-19.8 19.9-19.8z" fill="#fff" v-if ="store.dirLeft")


            g#svgMaterial(style="display: none;",:transform="'scale('+ sizeM(1) +')'")
              rect.reflex(width="480" height="280" rx="16" ry="16" fill="none")

            g#fix(v-if ="store.fixersDisplay") 
              circle(cx="16" cy="16" r="3" fill="#ccc")
              circle(:cx="sizeM(this.plateWidth)-16",cy="16" r="3" fill="#ccc")
              circle(:cx="sizeM(this.plateWidth)-16",:cy="sizeM(280)-16",r="3" fill="#ccc")
              circle(cx="16",:cy="sizeM(280)-16",r="3" fill="#ccc")        

        // SVG Template
        #containerTemplate(
          style="position: relative;"
          v-else)

          .cartPreview(
            :class="{ collection: isCollection}",
            ref="refCart",
            :style="'top:'+ this.rectY +'px; right: calc(100vw - '+ this.rectR +'px); width:'+ this.rectW +'px; height:'+ this.rectH +'px;'", v-html="animationBuff"         
            )

          svg#svgTemplate(
            :width="sizeM(480)", 
            :height="sizeM(280)",
            :viewBox="sizeM(-20) +' '+ sizeM(-20) +' '+ sizeM(500) +' '+ sizeM(320)",
            :class="{ material: isMaterial }", 
            @click="isMaterial = !isMaterial", 
            viewport xmlns="http://www.w3.org/2000/svg",
            preserveAspectRatio="xMinYMin meet")

            rect.plate(:width="sizeM(480)",:height="sizeM(280)" rx="16" ry="16" fill="#0075c9" stroke="#000" stroke-width=".25", ref="refReady")
            g#refElements(style="display: none;")
              rect(ref="refLimit",:width="sizeM(sizeXR(0)-this.plateLimit)",:height="sizeM(280)",:x="sizeM(50)" y="0" fill="none" stroke="none")            
            
            text.name(:x="sizeM(54)",:y="sizeM(54)",:font-size="sizeM(25)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-SemiBold; letter-spacing: .05em; fill: #fff;") {{mainFamily}}

            text.num(:x="sizeM(54)",:y="sizeM(245)",:font-size="sizeM(148)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .018em; fill: #fff;")
            
            g(:transform="'translate('+ sizeM(48) +','+ sizeM(245) +')'")          
              BuildingNumber(ref="refNum", :sizeMX = "0", :sizeMFont = "sizeM(this.fontNumSize)", anchor = "start", fillColor = "#fff")
            
            text.translit(:x="sizeM(54)",:y="sizeM(89)",:font-size="sizeM(25)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .034em; fill: #fff;") {{store.mainTranslit}}
            
            g#direction(:transform="'translate('+ sizeM(sizeXR(40)) +',' + sizeM(125) +'), scale(' + sizeM(-1) +','+ sizeM(1) +')'")
              path#forward(d="M50.9 21.4 29.5 0 8.1 21.4l3 3L27.2 8.2v111.5h4.2V7.9l16.5 16.5z" fill="#fff" v-if ="store.dirForward")
              path#right(d="M39.2 18.2H8.1L23.3 3l-3-3L0 20.3l20.3 20.3 3-3L8.1 22.4h31.1c8.6 0 15.6 7 15.6 15.6v82H59V38c0-10.9-8.9-19.8-19.8-19.8z" fill="#fff" v-if ="store.dirRight")
              path#left(path d="M19.9 18.2H51L35.8 3l3-3L59 20.3 38.8 40.6l-3-3L51 22.4H19.9c-8.6 0-15.6 7-15.6 15.6v82H0V38c0-10.9 8.9-19.8 19.9-19.8z" fill="#fff" v-if ="store.dirLeft")


            g#svgMaterial(style="display: none;",:transform="'scale('+ sizeM(1) +')'")
              rect.reflex(width="480" height="280" rx="16" ry="16" fill="none")

            g#fix(v-if ="store.fixersDisplay") 
              circle(cx="16" cy="16" r="3" fill="#ccc")
              circle(:cx="sizeM(this.plateWidth)-16",cy="16" r="3" fill="#ccc")
              circle(:cx="sizeM(this.plateWidth)-16",:cy="sizeM(280)-16",r="3" fill="#ccc")
              circle(cx="16",:cy="sizeM(280)-16",r="3" fill="#ccc")

            SvgRequirements(            
              :plateWidth = "this.plateWidth"
              :plateHeight = "this.plateHeight"
              :plateName = "this.plateName"
              :isWhiteBack = "false"
              :immutableSize = "false"
              )

        .sign-size.text-center 
          span розмір покажчика
          | {{sizeM(480)}}x{{sizeM(280)}} мм

    .row
      .col.svgContainer

  .container-fluid
    .row.row-cols-1.plate-options
      .col-xs-12.col-sm-8.offset-sm-2.col-md-6.offset-md-3
        .form-group
          .custom-control.custom-switch
            input.custom-control-input(type="checkbox" id="largerSize" v-model="store.sizeMultiplier")
            label.custom-control-label(for="largerSize") збільшений розмір покажчика
              br
              small.caption Якщо відстань від фасаду до краю хідника більше ніж&nbsp;15&nbsp;метрів      
      .col-xs-12.col-sm-8.offset-sm-2.col-md-6.offset-md-3
        .form-group
          .custom-control.custom-switch
            input#fixMarks.custom-control-input(type="checkbox" v-model="store.fixersDisplay")
            label.custom-control-label(for="fixMarks") показати кріплення
    
    .row.plate-download
      .col.text-center
        button#btn-download.btn.btn-primary.btn-lg(type="button", :disabled="generateNotReady", @click="convertFunc(false)") Завантажити                
        button#add-cart.btn.btn-primary.btn-lg(type="button", :disabled="generateNotReady", @click="convertFunc(true)") Додати до набору
    .row.plate-download
      .col-xs-12.col-sm-8.offset-sm-2.col-md-6.offset-md-3.text-center        
        p.caption.text-center Макет у форматі .pdf з літерами у кривих
          br
          br
          | Під час завантаження матеріалів ви погоджуєтесь з ліцензією 
          a(href="https://creativecommons.org/licenses/by-nd/4.0/deed.uk" target="_blank") CC BY-ND 4.0 Із 
          | Зазначенням Авторства — Без Похідних 4.0 Міжнародна
          
    .svgInstance 
      svg#svgInstance(:width="sizeM(480)", :height="sizeM(500)",:viewBox="sizeM(0) +' '+ sizeM(0) +' '+ sizeM(480) +' '+ sizeM(500)", viewport xmlns="http://www.w3.org/2000/svg")
        //- rect(width="100" height="100" fill="red")

</template>

<script>
import { store } from '@/store.js';

import * as pdfFuncs from '@/svgToPdfConvertJS.js';

import BuildingNumber from '@/components/BuildingNumber.vue'
import StreetName from '@/components/StreetName.vue'
import SvgRequirements from '@/components/SvgRequirements.vue'
import SvgDefs from '@/components/SvgDefs.vue'
import BulkDownloadList from '@/components/BulkDownloadList.vue'

export default {
  name: 'Number',
  components: {
    BuildingNumber,
    StreetName,
    SvgRequirements,
    SvgDefs,
    BulkDownloadList,
  },

  data(){
    return{
      store,

      inputName: '',

      radioValue: 0,

      mainFamily: '',
      mainNumberFinal: '',
      Direction: '',
      mainTranslit: '',

      mainFamilyPlace: 'вулиця Мандриківська',
      inputNumberPlace: '314',
      mainTranslitPlace: 'Mandrykivska St.',

      isMaterial: false,
      isPlaceholder: true,
      generateNotReady: true,
      isCollection: false,

      plateWidth:480,
      plateHeight:280,
      plateLimit:160,
      plateName: 'Напрямок до адреси',

      fontNumSizeMax:148,
      fontNumSizeMin:98,
      fontNumSize:148,
      fontNumOffset:20,

      rectR: 0,
      rectY: 0,
      rectW: 0,
      rectH: 0,

      animationBuff: 0,
    }
  },

  watch:
  {
    'store.inputNumber': function()
    {
      this.minReq();
    },

    'store.inputStreetName': function()
    {
      this.minReq();
    },

    'store.bulkData.length': function()
    {
      if(this.store.bulkData.length > 0)
      {
        this.store.isCartEmpty = false;
      }
      else
      {
        this.store.isCartEmpty = true;
      }
    },
  },

  methods:
  {
    streetDataSuggest()
    {
      this.mainFamily = '';
      this.store.mainTranslit = '';

      if ((this.store.streetData.type + this.store.streetData.name).length > 28) {
        // Insert code to replace вулиця > вул. etc
      }

      this.mainFamily = this.store.streetData.type + ' ' + this.store.streetData.name;
      this.store.mainTranslit = this.store.streetData.translation;
    },

    minReq()
    {
      if(this.store.streetData.name != this.store.inputStreetName)
      {
        this.mainFamily = '';
        this.store.mainTranslit = '';
      }

      if(this.store.inputNumber != '' || this.store.streetData.name == this.store.inputStreetName)
      {
        this.isPlaceholder = false;
        this.isMaterial = true;
        if(this.store.inputStreetName.length > 0 && this.store.streetData.name == this.store.inputStreetName)
        {
          this.streetDataSuggest();
        }
      }      
      else
      {
        this.isPlaceholder = true;
        this.isMaterial = false;
      }

      if(this.store.inputNumber != '' && this.store.streetData.name == this.store.inputStreetName)
      {
        this.generateNotReady = false;
      }
      else 
      {
        this.generateNotReady = true;
      }
      
      this.mainFamily = this.prefixShort(this.mainFamily);
    },

    prefixShort(inputStreetName)
    {
      var compareBuff = ['вулиця', 'провулок', 'площа', 'тупик', 'узвіз', 'бульвар', 'проспект', 'проїзд', 'шосе', 'майдан', 'житловий масив'];
      var replaceBuff = ['вул', 'пров', 'пл', 'туп', 'узв', 'бул', 'прос', 'прої', 'шосе', 'майдан', 'ж/м'];
      var compCheck = -1;
      if(inputStreetName.length >= 25)
      {
        for(let i = 0; i < compareBuff.length; i++)
        {
          if(inputStreetName.includes(compareBuff[i]))
          {
            compCheck = i;
          }
        }
      }

      if(compCheck > -1)
      {
        return inputStreetName.replace(compareBuff[compCheck], replaceBuff[compCheck]);
      }
      else
      {
        return inputStreetName;
      }
    },
    
    directions() 
    {
      this.store.dirLeft = false;
      this.store.dirForward = false;
      this.store.dirRight = false;
    },

    convertFunc(isBulk)
    {
      let dirEntrArrow = '';
      if(this.store.dirLeft)
      {
        dirEntrArrow = '_left';
      }
      else if(this.store.dirForward)
      {
        dirEntrArrow = '_forward';
      }
      else
      {
        dirEntrArrow = '_right';
      }

      let pdfMainName = (this.store.mainTranslit).toLowerCase().replace(/ /gm,'-').replace('.','')+ '-' + this.store.mainNumber + dirEntrArrow;
      let pdfNameOptions = '_direction-addr-sign';
      if(this.store.sizeMultiplier)
      {
        pdfNameOptions += '_XL';
      }
      if(this.store.fixersDisplay)
      {
        pdfNameOptions += '_FIX';
      }
      pdfNameOptions += '.pdf';

      pdfFuncs.svgToPdfCovert(10, 10, this.sizeM(480), this.sizeM(500), this.sizeM(480)+20, this.sizeM(450)+30, 
      pdfMainName, pdfNameOptions, this.sizeM(30), this.sizeM(310), this.sizeM(this.plateHeight), 'svgInstance', 'svgTemplate', isBulk, this.store.bulkData);

      if(isBulk)
      {
        this.getPosition();

        this.animationBuff = document.querySelector('#svgTemplate').outerHTML;

        let isCopy = false;

        if(typeof this.store.bulkData.length !== 'undefined')
        {
            for(let i = 0; i < this.store.bulkData.length; i++)
            {
                if(this.store.bulkData[i].pdfName == (pdfMainName + pdfNameOptions))
                {
                    isCopy = true;
                }  
            }
        }

        if(!isCopy)
        {
          setTimeout(() => {
            this.isCollection = true;
          }, 100); 
          setTimeout(() => {
            this.animationBuff = 0;
            this.isCollection = !this.isCollection;        
          }, 1000); 
        }
      }
    },

    getPosition() {      
      this.rectY = parseInt(this.$refs.refReady.getBoundingClientRect().y);
      this.rectW = parseInt(this.$refs.refReady.getBoundingClientRect().width);
      this.rectH = parseInt(this.$refs.refReady.getBoundingClientRect().height);
      this.rectR = parseInt(this.$refs.refReady.getBoundingClientRect().right);        
    },

    sizeM(size)
    {
      if(this.store.sizeMultiplier)
      {
        return size;
      }
      else
      {
        return size * 0.75;
      }
    },

    sizeXR(offset)
    {
      return this.plateWidth - offset;
    },
    
    inputField() {
      this.$refs.search.$refs.inputField.focus();      
    },

    getNumWidth() {           
      var limitBox = this.$refs.refLimit.getBoundingClientRect().width;      
      var contentBox = this.$refs.refNum.$el.getBoundingClientRect().width;      

      if(limitBox < contentBox) {
        return this.fontNumSize - this.fontNumOffset;
      }
      else if((limitBox > contentBox) && (this.fontNumSize == this.fontNumSizeMax)) {
        return this.fontNumSize;
      }
      else if((limitBox > contentBox) && (this.fontNumSize < this.fontNumSizeMax) && ((limitBox - contentBox) > 30)) {
        return this.fontNumSize + this.fontNumOffset;
      }     
      else {        
        return this.fontNumSize;
      }
    },
  },
  beforeMount() {
    if (this.store.mainTranslit) {
      this.isPlaceholder = false;
      this.isMaterial = true;
    }
  },

  mounted() {
    this.inputField();
    this.minReq();
  }, 

  updated(){
    this.$nextTick(()=>{
      if(!this.isPlaceholder) {        
        this.fontNumSize = this.getNumWidth();
      }
    })
  },
}

</script>

<style lang="scss" scoped>
@import '@/assets/styles/_svg-templates.scss';

@font-face {
  font-family: "DniproCity";
  src: url('/fonts/DniproCity-Regular.woff2');
  font-weight: 400;
}

@font-face {
  font-family: "DniproCity-Medium";
  src: url('/fonts/DniproCity-Medium.woff2');
  font-weight: 400;
}

@font-face {
  font-family: "DniproCity-SemiBold";
  src: url('/fonts/DniproCity-SemiBold.woff2');
  font-weight: 400;
}

@font-face {
  font-family: "DniproCity-Bold";
  src: url('/fonts/DniproCity-Bold.woff2');
  font-weight: 400;
}


h2 {            
  margin: 0 0 20px 0;  
  font-family: DniproOpen, sans-serif;
  font-weight: 500;
  font-size: 24px;
} 


// Material look
.material {  
  polygon.shade {
    fill: #000;
    filter: url(#svgBlur);
    opacity: .05;    
  }
  rect.reflex {
    fill: none;
    stroke: url(#edgeBlue);
    stroke-width: 3px;
  } 
  
  rect.plate {    
    fill: url(#plateGradientBlue);
    filter: url(#svgDropShadow);     
  }
  #svgMaterial {
    display: block !important;
    opacity: 1;    
  }
}

// Fixes {
#svgTemplate #fix, #svgPlaceholder #fix {
  circle {
    fill: #000000 !important;
    opacity: .3;
  }
}

.container-fluid {
  max-width: 600px;
}

.preview {
  min-height: 350px;
  @include respond-below(md) {
    min-height: 240px;
    svg {
      width: 100%;
      padding: 0 20px;
      height: auto;
    }
  }
}

.directions {
  .btn {
    padding: 1px 4px;
    svg {
      width: 32px;
      height: 32px;
    }
  }
}

.cartPreview {  
  display: inline-block; 
  width: 300px;
  height: 160px;

  position: fixed;
  top: 200px;
  right: calc(50% - 300px);
  
  visibility: hidden;
  opacity: 1;
  background-color: transparent;
  border-radius: 8px;
  box-shadow: 0 4px 80px 0 rgba(0,0,0,.3);  
  border: none;

  &.collection {    
    visibility: visible;
    top: 50px !important;
    right: 50px !important;
    // background-color: rgba(255,255,255,1);
    
    opacity: 0;
    // z-index: 10000;
    box-shadow: 0 4px 20px 0 rgba(0,0,0,.3);

    transform: scale(.1);
    transform-origin: top right;

    transition: top .5s cubic-bezier(0, 0, 0.3, 1.8), right .5s ease-out, transform .5s ease-out, opacity .75s ease-in, box-shadow .5s ease-out;

  }
}

</style>